import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Link as MuiLink,
    useMediaQuery,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { styled, useTheme } from '@mui/material/styles';
import Select from 'react-select';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

export class Option {
    value: string;
    label: string;
}
export class FAQDetails {
    category: string[];
    subCategory: string;
    questionText: string;
    answerText: any;
}

const StyledAccordion = styled(Accordion)({
    '&:nth-of-type(1n)': {
        borderBottom: '1px solid #D5D6DA',
    },

    '&: MuiAccordion-root': {
        borderColor: '#A5AAAF',
    },
});

const StyledSelect = styled(Select)({
    '&>.select__control': {
        padding: '0.5em',
        borderRadius: '11px',
        borderColor: '#A5AAAE',

        '& > .select__indicators > .select__indicator-separator': {
            display: 'none',
        },

        '& > .select__indicators > .select__indicator': {
            color: 'inherit',
        },
    },
});

function FAQContent(faqDetails: FAQDetails[]) {
    const [faqData, setFaqData] = useState<FAQDetails[]>(faqDetails);
    const [expanded, setExpanded] = useState<number | false>(false);
    const [category, setCategory] = useState<string>('');
    const [subCategory, setSubCategory] = useState<Option | null>(null);
    const [subCategoryKey, setSubCategoryKey] = useState<string>('subCatKey');
    const [selected, setSelected] = useState<number | null>(null);
    const [categoryOptions, setCategoryOptions] = useState<Option[]>([]);
    const [subCategoryOptions, setSubCategoryOptions] = useState<Option[]>([]);
    const [filteredQuestions, setFilteredQuestions] = useState<string[]>([]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    const handleOnChangeCategory = (selectedValueOption: Option) => {
        const { value } = selectedValueOption;
        if (value !== category) {
            setCategory(value);
            setSubCategory(null);
            setSubCategoryOptions(null);
            setSubCategoryKey(`subCatKey${value.split(' ').join('')}`);
        }
    };
    const handleOnChangeSubCategory = (selectedValueOption: Option) => {
        const { value } = selectedValueOption;
        setSubCategory({ value: value, label: value });
        setFilteredQuestions(getFilteredQuestions(value));
        setExpanded(0);
    };

    const getFilteredQuestions = (subCatVal: string) => {
        return Object.keys(faqData).filter(
            (q) =>
                (!subCatVal || subCatVal === faqData[q].subCategory) &&
                (!category || faqData[q].category.includes(category))
        );
    };

    const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const ULText = ({ children }) => (
        <ul className="list-outside list-disc BigScreen:ml-24 Mobile:ml-4">
            {children}
            <br />
        </ul>
    );
    const OLText = ({ children }) => (
        <ol className="list-outside list-decimal BigScreen:ml-24 Mobile:ml-4">
            {children}
            <br />
        </ol>
    );
    const H1Text = ({ children }) => <Typography variant="h1">{children}</Typography>;
    const H2Text = ({ children }) => <Typography variant="h2">{children}</Typography>;
    const H3Text = ({ children }) => <Typography variant="h3">{children}</Typography>;
    const Text = ({ children }) => <>{children}</>;

    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
            [BLOCKS.HEADING_1]: (node, children) => <H1Text>{children}</H1Text>,
            [BLOCKS.HEADING_2]: (node, children) => <H2Text>{children}</H2Text>,
            [BLOCKS.HEADING_3]: (node, children) => <H3Text>{children}</H3Text>,
            [BLOCKS.UL_LIST]: (node, children) => <ULText>{children}</ULText>,
            [BLOCKS.OL_LIST]: (node, children) => <OLText>{children}</OLText>,
            [INLINES.HYPERLINK]: (node, children) => (
                <MuiLink
                    href={node.data.uri}
                    className="text-purple-1 cursor-pointer hover:underline"
                    sx={{ textDecoration: 'none' }}
                >
                    {children}
                </MuiLink>
            ),
        },
        renderText: (text) => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
        renderMarks: {
            [MARKS.BOLD]: (node, children) => <strong>{children}</strong>,
        },
    };

    useEffect(() => {
        setFaqData(faqDetails);

        let categories = Object.keys(faqData).map((q) => faqData[q].category[0]);
        let uniqueCategories = [...new Set(categories)];

        let catOpts = Object.keys(uniqueCategories).map((key) => {
            return { value: uniqueCategories[key], label: uniqueCategories[key] };
        });

        setCategoryOptions(catOpts);
        let filteredSubcategories = Object.keys(faqData).filter((q) => faqData[q].category[0] === category);
        let subCategories = filteredSubcategories?.map((q) => faqData[q].subCategory);
        let uniqueSubCategories = [...new Set(subCategories)];

        let subCatOpts = Object.keys(uniqueSubCategories).map((key) => {
            return { value: uniqueSubCategories[key], label: uniqueSubCategories[key] };
        });
        setSubCategoryOptions(subCatOpts);
        if (subCatOpts && subCatOpts.length > 0) {
            setExpanded(0);
        }
    }, [faqDetails, category, subCategory]);

    return (
        <>
            <Grid direction="column" display="flex" justifyContent="center" container>
                <Container maxWidth="lg">
                    <Grid direction="column" display="flex" justifyContent="center" sx={{ height: '400px' }} my={10}>
                        <Typography textAlign="center" fontSize={isMobile ? '1.625rem' : '2.5rem'} pb={2}>
                            Please help us by selecting the area your enquiry is about.
                        </Typography>
                        <Box sx={{ px: !isMobile && '10%' }}>
                            <StyledSelect
                                className="basic-single"
                                classNamePrefix="select"
                                options={categoryOptions}
                                onChange={handleOnChangeCategory}
                                placeholder="Category"
                                isSearchable={false}
                                maxMenuHeight={200}
                            />
                        </Box>
                        <Box sx={{ px: !isMobile && '10%', visibility: category ? 'visible' : 'hidden', pt: 2 }}>
                            <StyledSelect
                                className="basic-single"
                                classNamePrefix="select"
                                options={subCategoryOptions}
                                onChange={handleOnChangeSubCategory}
                                placeholder="Sub Category"
                                value={subCategory}
                                isSearchable={false}
                                maxMenuHeight={150}
                                isLoading={subCategoryOptions === null}
                            />
                        </Box>
                    </Grid>
                </Container>
                {category && subCategory && (
                    <Grid
                        direction="column"
                        display="flex"
                        justifyContent="space-evenly"
                        sx={{ backgroundColor: '#f0f3ff' }}
                    >
                        <Container maxWidth="lg" sx={{ my: 5 }}>
                            <Typography
                                textAlign="center"
                                fontWeight="600"
                                fontSize={isMobile ? '1.625rem' : '2.5rem'}
                                sx={{ mb: 5 }}
                            >
                                Please look through our{' '}
                                <Box component="span" color="text.secondary">
                                    FAQs
                                </Box>{' '}
                                or contact us
                            </Typography>

                            {filteredQuestions.length > 0 && (
                                <Grid sx={{ mb: 5, mx: 5 }}>
                                    {filteredQuestions.map((q, index) => (
                                        <Box>
                                            <StyledAccordion
                                                elevation={0}
                                                key={`query-${index}`}
                                                expanded={expanded === index}
                                                onChange={handleChange(index)}
                                                square
                                                sx={{
                                                    borderColor: '#A5AAAF',
                                                }}
                                                id={index === 2 && 'btn-home-faqs-question-ans'}
                                            >
                                                <AccordionSummary
                                                    aria-controls="panel1d-content"
                                                    expandIcon={
                                                        expanded === index ? (
                                                            <RemoveIcon sx={{ color: 'black' }} />
                                                        ) : (
                                                            <AddIcon sx={{ color: 'black' }} />
                                                        )
                                                    }
                                                >
                                                    <Typography
                                                        variant="h2"
                                                        fontWeight="600"
                                                        sx={{
                                                            fontSize: '1.25rem;',
                                                            lineHeight: '1.5rem',
                                                        }}
                                                    >
                                                        {faqData[q].questionText}
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails sx={{ px: 5 }}>
                                                    {renderRichText(faqData[q].answerText, options)}
                                                </AccordionDetails>
                                            </StyledAccordion>
                                        </Box>
                                    ))}
                                </Grid>
                            )}

                            <Typography
                                textAlign="center"
                                fontWeight="600"
                                fontSize={isMobile ? '1.25rem' : '1.5rem'}
                                fontFamily="ApercuLight, sans-serif"
                            >
                                Still have a Question?{' '}
                                <MuiLink
                                    href={process.env.GATSBY_MYASURION_CONTACT_URL}
                                    target="_blank"
                                    sx={{ textDecorationColor: '#8223D2' }}
                                >
                                    Contact Us Here
                                </MuiLink>
                            </Typography>
                        </Container>
                    </Grid>
                )}
            </Grid>
        </>
    );
}

export default FAQContent;
