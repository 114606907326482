import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import FAQHero from '../components/FAQHero';
import FAQContent, { FAQDetails } from '../components/FAQContent';
import Layout from '../components/LayoutComponent';
import Spinner from '../components/common/Spinner';
import Seo from '../components/SeoComponent';

function Faqs({ data }) {
    const [pageLoading, setPageLoading] = useState<boolean>(true);
    const faqDetails: FAQDetails[] = get(data, 'allContentfulFaQsHomePage.nodes');
    useEffect(() => {
        setPageLoading(false);
    }, [data]);

    return (
        <>
            <Layout
                subcomponent={
                    <>
                        {pageLoading ? (
                            <Spinner />
                        ) : (
                            <>
                                <Seo
                                    title="Phone Insurance | Frequently Asked Questions"
                                    description="Asurion Mobile⁺ covers your phone in the event of accidental damage, loss or theft. Insure your phone based on the level of coverage you want."
                                    image=""
                                    canonical={'/faqs'}
                                />
                                <FAQHero />
                                <FAQContent {...faqDetails} />
                            </>
                        )}
                    </>
                }
            />
        </>
    );
}

export default Faqs;

export const pageQuery = graphql`
    query FAQsQuery {
        allContentfulFaQsHomePage(filter: { node_locale: { eq: "en-US" } }) {
            nodes {
                category
                subCategory
                questionText
                answerText {
                    raw
                }
            }
        }
    }
`;
