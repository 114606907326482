import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';

function FAQHero() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid
            display="flex"
            height="400px"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            sx={{ background: 'linear-gradient(90.13deg, #8223D2 0%, #3700B3 100%)' }}
            container
        >
            <Grid>
                <Typography
                    variant="h1"
                    fontSize={isMobile ? '2.5rem' : '3.75rem'}
                    textAlign="center"
                    color="text.white"
                >
                    Frequently Asked
                    <br /> Questions
                </Typography>
            </Grid>
        </Grid>
    );
}

export default FAQHero;
